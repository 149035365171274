import React from 'react';
import TermsText from './terms-text'
import './modal.styles.scss';

const Modal = ({toggleModal, showModal}) => {
    return(
        <div id="myModal" className={showModal ? "modal modal-show" : "modal modal-hide" }>
            <div className="modal-content">
                <div className="modal-header">
                <div className='logo'>
                    <span>Meshique</span>
                </div>
                    <span className="close" onClick={(e) => toggleModal(e) } >&times;</span>
                </div>
                
                <div className="modal-scroll-text">
                    <TermsText />
                </div>
                <div className="modal-footer">
                    <button type='button' className='button is-black nomad-btn' onClick={(e) => toggleModal(e) } >
                        CLOSE
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Modal;