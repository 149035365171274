import React from 'react';
import { withRouter } from 'react-router-dom';
import featureImage from '../../assets/feature_image.jpg';
import './main-section.styles.scss';

const MainSection = ({ history }) => {
  return (
    <div className='main-section-container'>
      <div className='main-section-middle'>
        <div className='ms-m-image'>
          <img src={featureImage} alt='under water'/>
        </div>
        <div className='ms-m-description'>
          <h2>About</h2>
          <p>
          We make dresses that effortlessly transition from day to night. 
            From the catwalk to the office, and everywhere in between, 
            each Meshique piece is thoughtfully designed and crafted to be the perfect balance of 
            form and function.
          </p>
        </div>
      </div>
    </div>
  );
}

export default withRouter(MainSection);