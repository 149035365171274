import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Context as ProductsContext  } from '../../../context/products-context';

const Total = ({ itemCount, total, history, clearCart, clearAmount }) => {
  const {state:{ selectedPostageType}} = useContext(ProductsContext);
  return (
    <div className='total-container'>
      <div className='total'>
        <p>Total Items: {itemCount}</p>
        <p>{`Subtotal: £${total.toFixed(2)}`}</p>
        {
          selectedPostageType
          ?  <p>{`+ Shipping: £${parseFloat(selectedPostageType.price).toFixed(2)}`}</p>
          : null
        }
        {
          selectedPostageType
          ?  <p><strong>{`Total: £${(parseFloat(selectedPostageType.price) + total).toFixed(2)}`}</strong></p>
          : null
        }
       
      </div>
      <div className='checkout'>
        <button 
          className='button is-black' 
          onClick={() => history.push('/checkout')}>CHECKOUT</button>
        <button 
          className='button is-white' 
          onClick={() => {
            clearCart();
            clearAmount();
          }}
        >CLEAR</button>  
      </div>
    </div>
  );
}

export default withRouter(Total);