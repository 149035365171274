import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'; // for the db
import 'firebase/compat/auth';

const config =  {
  apiKey: "AIzaSyAY0FF4aAaVhAbDlsKmOPjJl3w91iJnWgA",
  authDomain: "nomad-bags-store-933f3.firebaseapp.com",
  projectId: "nomad-bags-store-933f3",
  storageBucket: "nomad-bags-store-933f3.appspot.com",
  messagingSenderId: "123284476307",
  appId: "1:123284476307:web:7538ee1f58f4e288055866"
};

firebase.initializeApp(config);

const firestore = firebase.firestore();
const auth = firebase.auth();

const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) { return };

  const userRef = firestore.doc(`users/${userAuth.multiFactor.user.uid}`); //users/uniq26535
  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData
      });
    } catch (error) {
      console.log('error creating user', error.message);
    }
  }

  return userRef;
}

export {
  firestore,
  createUserProfileDocument,
  auth,
}