import React from 'react';
import { formatPrice } from '../../../helpers';
import { PlusCircleIcon, MinusCircleIcon, TrashIcon } from '../../icons/';

const CartItem = (props) => {
  const {  name, picture, price, quantity, id, description,stock_levels:stockLevels, increase, decrease, removeProduct, stockId } = props;
  const product = { name, picture, price, quantity, id, description };

  return (
    <div className='cart-item'>
      <div className='item-image'>
        <img src={picture} alt='product' />
      </div>
      <div className='name-price'>
        <h4>{name}</h4>
        <p><strong>Size:</strong> {stockLevels.find( sl => sl.id === stockId).size}</p>
        <p>£{formatPrice(price)}</p>
      </div>
      <div className='quantity'>
        <p>{`Quantity: ${quantity}`}</p>
      </div>
      <div className='btns-container'>
        <button
        className='btn-increase' onClick={() => increase(product,stockId)}>
          <PlusCircleIcon width='20px' />
        </button>
        {
          quantity === 1 && 
          <button
          className='btn-trash' onClick={() => removeProduct(product,stockId)}>
            <TrashIcon width='20px' />
          </button>
        }
        {
          quantity > 1 && 
          <button
          className='btn-decrease' onClick={() => decrease(product,stockId)}>
            <MinusCircleIcon width='20px' />
          </button>
        }
      </div>
    </div>
  );
}

export default CartItem;