import React, { useState } from 'react';
import { Formik } from 'formik';
import Spacer from '../../spacer/spacer';
import Modal from '../../pages/terms/modal';
import './shipping-address.styles.scss';



const validate = values => {
  const { name, email, address1,address2,town,postcode } = values;
  const errors = {};
  if (!email) { errors.email = 'Required'}; 
  if (!name) { errors.name = 'Required' };
  if (!address1) { errors.address1 = 'Required'};
  if (!town) { errors.town = 'Required'};
  if (!postcode) { errors.postcode = 'Required'};
    
  return errors;
}

const ShippingAddress = ({ setShipping }) => {
  const initialValues = {
    email: '',
    name: '',
    address1: '',
    address2: '',
    town: '',
    postcode: '',
  }

  const [showModal,setShowModal] = useState(false);

  const toggleModal = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  }

  return (
    <div>
      <Modal toggleModal={toggleModal} showModal={showModal} />
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={(values) => {
          setShipping(values);
        }}
      >
        {
          ({ values, errors, handleChange, handleSubmit }) => {
            const { name, email, address1,town,postcode } = errors;
            return (
              <form onSubmit={handleSubmit}>
                <h4>Personal</h4>
                <Spacer height='1rem' />
                <div>
                  <input 
                    type='text'
                    name='name'
                    onChange={handleChange}
                    value={values.name}
                    placeholder='Name'
                    className={ 'nomad-input ' + (name ? 'error' : '') }
                  /> 
                </div>
                <div>
                  <input 
                    type='email'
                    name='email'
                    onChange={handleChange}
                    value={values.email}
                    placeholder='Email'
                    className={ 'nomad-input ' + (email ? 'error' : '') }
                  /> 
                </div>
                <h4>Shipping Address</h4>
                <Spacer height='1rem' />
                <div>
                  <input 
                    type='text'
                    name='address1'
                    onChange={handleChange}
                    value={values.address1}
                    placeholder='Address Line 1'
                    className={ 'nomad-input ' + (address1 ? 'error' : '') }
                  /> 
                </div>
                <div>
                  <input 
                    type='text'
                    name='address2'
                    onChange={handleChange}
                    value={values.address2}
                    placeholder='Address Line 2'
                    className={ 'nomad-input ' }
                  /> 
                </div>
                <div>
                  <input 
                    type='text'
                    name='town'
                    onChange={handleChange}
                    value={values.town}
                    placeholder='Town / City'
                    className={ 'nomad-input ' + (town ? 'error' : '') }
                  /> 
                </div>
                <div>
                  <input 
                    type='text'
                    name='postcode'
                    onChange={handleChange}
                    value={values.postcode}
                    placeholder='Postal Code'
                    className={ 'nomad-input ' + (postcode ? 'error' : '') }
                  /> 
                </div>
                <div className='nomad-input terms-check'> 
                  <label htmlFor="terms">I accept the <a href="" onClick={(e) => toggleModal(e) } >terms and conditions.</a>
                    <input
                      id='terms' 
                      type='checkbox'
                      name='terms'
                      placeholder='Postal Code'
                      required
                    /> 
                  </label>
                </div>
                
                <div className='submit-btn'>
                  <button type='submit' className='button is-black nomad-btn submit'>
                    CONTINUE
                  </button>
                </div>
              </form>

            );
          }
        }
      </Formik>
    </div>
  );
};

export default ShippingAddress;