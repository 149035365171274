import React, { useContext, useEffect } from 'react';
import { Context as ProductsContext } from '../../context/products-context';
import FeaturedProduct from '../shared/featured-product';

const FeaturedCollection = () => {
  const {getFeatured,state:{featuredProducts}} = useContext(ProductsContext);
  const productItems = featuredProducts.filter((product, i) => i < 4).map(product => (
    <FeaturedProduct {...product} key={product.id} />
  ));

  useEffect( () => {
    getFeatured();
  },[]);



  return (
    <>
    {
      featuredProducts.length > 0
      ?  <div className='featured-collection container'>
        <h2 className='featured-section-title'>Featured Collection</h2>
        <div className='products'>
          {
            productItems
          }
        </div>
      </div>
      : null
    }
    </>
  );
}

export default FeaturedCollection;