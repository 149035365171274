import React, { useContext, useEffect, useState}  from 'react';
import { formatPrice } from '../../../helpers';
import { Context as ProductsContext  } from '../../../context/products-context';


const Postage = ({setPostageType}) => {
  const {state:{ selectedPostageType}} = useContext(ProductsContext);

  return (
    <>
    {
      selectedPostageType
      ? <div className='cart-item'>
          <div>
            <h4>Postage and packaging</h4>
          </div>
          <div>
            <p>{selectedPostageType.postage_type}</p>
          </div>
          <div>
            <p>£ {formatPrice(selectedPostageType.price)}</p>
          </div>
        </div>
      : null
    }
    </>

  );
}

export default Postage;