import createDataContext from './createdatacontext';

const BASEURL = process.env.REACT_APP_BACKEND_BASE_URL;

const postageFromStorage = localStorage.getItem('postage') ? 
  JSON.parse(localStorage.getItem('postage')) : null;

const storePostage = (postage) => {
  localStorage.setItem('postage', JSON.stringify(postage));
}

const defaultState = {
  products: [],
  postageTypes: [],
  featuredProducts: [],
  selectedPostageType: postageFromStorage,
};

const apiReducer = (state,action) => {

  switch(action.type) {
    case 'add_error':
      return {...state,errorMessage:action.payload,errorState:true};
    case 'getProducts':
      return {...state,products:action.payload};
    case 'getFeatured':
        return {...state,featuredProducts:action.payload};
    case 'getPostageTypes':
        return {...state,postageTypes:action.payload};
    case 'setSelectedPostageType':
      storePostage(action.payload);
        return {...state,selectedPostageType:action.payload};
    case 'getSelectedPostageType':
      return {...state,selectedPostageType:postageFromStorage};
    case 'clearAmount':
      localStorage.removeItem('postage');
      return {...state,selectedPostageType:null}
    default:
      return defaultState;
  }
};

// const getConfig = () => {
//   return {
//     headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${localStorage.getItem("access_token")}`
//       }
//   }
// }

const getProducts = (dispatch) => async (category) => {

    await fetch(BASEURL + `getitems/?category=${category}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
        'X-CSRFToken': '{{ csrf_token }}',
        }
      }).then(response => response.json())
      .then( async data => {
          await dispatch({ type: 'getProducts', payload: data })
      }).catch(err => console.log(err));
}


const getFeatured = (dispatch) => async () => {

      await fetch(BASEURL + `getfeatured`, {
          method: 'GET',
          mode: 'cors',
          headers: {
          'X-CSRFToken': '{{ csrf_token }}',
          }
        }).then(response => response.json())
        .then( async data => {
            await dispatch({ type: 'getFeatured', payload: data })
        }).catch(err => console.log(err));
  }
  
  const getPostageTypes = (dispatch) => async () => {

    await fetch(BASEURL + `getpostage`, {
        method: 'GET',
        mode: 'cors',
        headers: {
        'X-CSRFToken': '{{ csrf_token }}',
        }
      }).then(response => response.json())
      .then( async data => {
          await dispatch({ type: 'getPostageTypes', payload: data })
      }).catch(err => console.log(err));
}


const setSelectedPostageType = (dispatch) => async (postageType) => {
    await dispatch({ type: 'setSelectedPostageType', payload: postageType });
}

const getSelectedPostageType = (dispatch) => async () => {
  //For now this is null because we hard code the standard postage
  let postageType = null;

  await dispatch({ type: 'getSelectedPostageType', payload: postageType });
}

const clearAmount = (dispatch) => async () => {
  await dispatch({ type: 'clearAmount', payload: null });
}





export const {Provider, Context} = createDataContext (
  apiReducer,
  { getProducts,getFeatured,getPostageTypes,setSelectedPostageType, getSelectedPostageType, clearAmount },
  {...defaultState}
);