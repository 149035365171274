//import { auth } from './firebase';

// export const isInCart = (product, cartItems) => {
//   return cartItems.find(item => item.id === product.id);
// }

export const isInCart = (stockId, cartItems) => {
  return cartItems.find(item => item.stockId === stockId);
}

//const API = 'http://localhost:8080';
const API = process.env.REACT_APP_PAYMENT_PROXY_URL;

export async function fetchFromAPI(endpoint, opts) {
  console.log('API', API);
  const { method, body } = { method: 'POST', body: null, ...opts };
  //const user = auth.currentUser;
  //const token = user && (await user.getIdToken());
  const res = await fetch(`${API}/${endpoint}`, {
    method,
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      'Content-Type': 'application/json',
      //Authorization: `Bearer ${token}`
    },
  });

  if (res.status === 200) {
    return res.json();
  } else {
    throw new Error(res.statusText);
  }
}

export const formatPrice = (priceStr) => {
  let strParts = priceStr.split('.');
  let afterDecimal = strParts[1].substring(0,2);
  return strParts[0] + '.' + afterDecimal;

}