import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../../../context/cart-context';
import { Context as ProductsContext  } from '../../../context/products-context';
import Layout from '../../shared/layout';
import CartItem from './cart-item';
import Total from './total';
import './cart-page.styles.scss';
import Postage from './postage';

const CartPage = () => {
  const { cartItems, itemCount, total, increase, decrease, removeProduct, clearCart } = useContext(CartContext);
  const funcs = { increase, decrease, removeProduct }
  const {getPostageTypes,setSelectedPostageType,clearAmount,state:{postageTypes,selectedPostageType}} = useContext(ProductsContext);

  useEffect(() => {
    if(postageTypes.length <= 0) {
      getPostageTypes();
    }
    if(postageTypes.length > 0) {
      setSelectedPostageType(postageTypes.find( item => item.postage_type === 'standard'));
    }
  }, [postageTypes, selectedPostageType]);

  //For now there is only one postage type so we just select standard from the returned data
  const setPostageType = async () => {
    await getPostageTypes();
    //For now the selected postage type is hard coded, but this will be where it is set using useState if we use a selctor
  }

  return (
    <Layout>
      <>
        <h1>Cart</h1>
        {
          cartItems.length === 0 ? <div className='empty-cart'>Your Cart is empty</div>
          : 
          <>
            <div className='cart-page'>
              <div className='cart-item-container'>
                {
                  cartItems.map(item  => <CartItem { ...item } key={item.stockId} { ...funcs } stockId={item.stockId} />)
                }
                <Postage setPostageType={setPostageType} postageType={selectedPostageType} />
              </div>
              <Total itemCount={itemCount} total={total} clearCart={clearCart} clearAmount={clearAmount} selectedPostageType={selectedPostageType} />
            </div>
          </>
        }
      </>
    </Layout>
  );
}

export default CartPage;