import React, { createContext, useReducer } from 'react';
import cartReducer, { sumItems } from './cart-reducer';

export const CartContext = createContext();

const cartFromStorage = localStorage.getItem('cart') ? 
  JSON.parse(localStorage.getItem('cart')) : [];

const initialState = { cartItems: cartFromStorage, ...sumItems(cartFromStorage) };

const CartContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);
  const addProduct = (product,stockId) => dispatch({ type: 'ADD_ITEM', payload: { ...product, stockId} });
  const increase = (product,stockId) => dispatch({type: 'INCREASE', payload: { ...product, stockId}});
  const decrease = (product,stockId) => dispatch({ type: 'DECREASE', payload: { ...product, stockId}});
  const removeProduct = (product,stockId) => dispatch({ type:'REMOVE_ITEM', payload: { ...product, stockId} });
  const clearCart = () => dispatch({ type: 'CLEAR' });
  const addShippingCost = (shippingCost) => dispatch({ type:'ADD_SHIPPING', payload: { shippingCost } });

  const contextValues = {
    ...state,
    addProduct,
    increase,
    decrease,
    removeProduct,
    clearCart,
    addShippingCost,
  }

  return (
    <CartContext.Provider value={ contextValues }>
      {
        children
      }
    </CartContext.Provider>
  );
}

export default CartContextProvider;