import React, { useContext } from 'react';
import { isInCart, formatPrice } from '../../helpers';
import { CartContext } from '../../context/cart-context'; 
import { withRouter } from 'react-router-dom';
import './featured-product.styles.scss';

const FeaturedProduct = (props) => {
  const { name, picture, price, history, id, description, stock_levels: stockLevels, has_sizes: hasSizes } = props;
  const product = { name, picture, price, id, description };
  const { addProduct, increase, cartItems } = useContext(CartContext);

  return (
    <div className='featured-product'>
      <div className='featured-image'  >
        <img src={picture} alt='product' className='shop-image' onClick={ () => { history.push(`/product/${id}`)} }/>
        <div className='name-price'>
          <h3>{name}</h3>
          <p>£ {formatPrice(price) }</p>
          {
            !isInCart(stockLevels[0].id,cartItems) && !hasSizes
            ? <button className='button is-black nomad-btn'
                onClick={ () => { addProduct(product,stockLevels[0].id)}}
              >
                ADD TO CART
              </button>
            : null
          }
          {
            isInCart(stockLevels[0].id,cartItems) && !hasSizes
            ? <button className='button is-white nomad-btn'
              id = 'btn-white-outline'
              onClick={()=> increase(product, stockLevels[0].id)}
              >
                ADD MORE
              </button>
            : null
          }
          {
            hasSizes 
            ? <button className='button is-black nomad-btn'
            onClick={() => { history.push(`/product/${id}`) }}
              >
                VIEW PRODUCT
              </button>
            : null
          }
          
        </div>
      </div>
    </div>
  );
}

export default withRouter(FeaturedProduct);