import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Context as ProductsContext } from '../../context/products-context';
import { CartContext } from '../../context/cart-context';
import { isInCart, formatPrice } from '../../helpers';
import Layout from '../shared/layout';
import SizeButton from '../size-button/size-button';
import './single-product.styles.scss';

const SingleProduct = ({ match, history: { push } }) => {
  const { state:{products, featuredProducts}}= useContext(ProductsContext);
  const { addProduct, cartItems, increase } = useContext(CartContext);
  const { id } = match.params;
  const [product, setProduct] = useState(null);
  const [size, setSize] = useState(null);

  useEffect(() => {
    let product = products.find(item => Number(item.id) === Number(id));
    //Try featured products if products is empty
    if(products.length ==0) {
      product = featuredProducts.find(item => Number(item.id) === Number(id));
    }

    // if product does not exist, redirec to shop page
    if (!product) {
      return push('/shop');
    }

    setProduct(product);
  }, [id, product, push, products,featuredProducts]);

  

  // while we check for product
  if (!product) { return null }
  const { picture, name, price, description, stock_levels: stockLevels, has_sizes: hasSizes } = product;

  return (
    <Layout>
      <div className='single-product-container'>
        <div className='product-image'>
          <img src={picture} alt='product' />
        </div>
        <div className='product-details'>
          <div className='name-price'>
            <h3>{name}</h3>
            <p>£ { formatPrice(price) }</p>
          </div>
          {
          hasSizes &&
           <div className="available-sizes">
              <h3>Available Sizes</h3>
              { stockLevels.map(sl =>  <SizeButton key={sl.id} stockLevel={sl} setSize={setSize}/>

              )}
            </div>
          }
          <div className='add-to-cart-btns'>
            {
              size
              ? !isInCart(size, cartItems)
                ?  <button 
                    className='button is-white nomad-btn' 
                    id='btn-white-outline'
                    onClick={() => addProduct(product,size)}>
                      ADD TO CART
                  </button> 
                : <button 
                    className='button is-white nomad-btn' 
                    id='btn-white-outline'
                    onClick={()=> increase(product,size)}>
                      ADD MORE
                  </button>
            : hasSizes
             && <button 
                className='button is-white nomad-btn' 
                id='btn-white-outline'
                onClick={null}>
                  SELECT SIZE
              </button> 
            }
            {
              !hasSizes 
              ? !isInCart(stockLevels[0].id, cartItems)
                ?  <button 
                    className='button is-white nomad-btn' 
                    id='btn-white-outline'
                    onClick={() => addProduct(product,stockLevels[0].id)}>
                      ADD TO CART
                  </button> 
                : <button 
                    className='button is-white nomad-btn' 
                    id='btn-white-outline'
                    onClick={()=> increase(product,stockLevels[0].id)}>
                      ADD MORE
                  </button>
              : null
            }
            {/* {
              itemInCart && size
              ? <button 
                  className='button is-white nomad-btn' 
                  id='btn-white-outline'
                  onClick={()=> increase(product)}>
                    ADD MORE
                </button>
              : <button 
                className='button is-white nomad-btn' 
                id='btn-white-outline'
                onClick={null}>
                  SELECT SIZE
              </button> 
            } */}
            {/* <button 
              className='button is-white nomad-btn' 
              id='btn-white-outline'>
                ADD TO CART
            </button> 
            <button 
              className='button is-white nomad-btn' 
              id='btn-white-outline'>
                ADD MORE
            </button>
            <button className='button is-black nomad-btn' id='btn-white-outline'>
              PROCEED TO CHECKOUT
            </button> */}
          </div>
          <div className='product-description'>
            <p>
              { description }
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(SingleProduct);