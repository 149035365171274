import React from 'react';


const TermsText = () => {
    return (
        <>
            <h1>Terms and Conditions for Use of the Website</h1>
            <div className="info-text">
                <p><strong>Meshique © 2022 is an e-commerce site with the sole purpose of selling fashion products. By browsing the site and / or 
                    making purchases, you are agreeing to the terms and conditions listed below.</strong></p>
            </div>
            <h2>Delivery Policy</h2>
            <div className="info-text">
                <p>We only ship to addresses with the United Kingdom only. Our delivery charge is £3.00 GBP per order. Orders are dispatched using 
                    Royal Mail standard delivery and we aim to have the products shipped to your delivery address within 3-5 days.</p>
            </div>
            <h2>Returns</h2>
            <div className="info-text">
                <p>We offer a full refund policy within 14 days from receipt of the goods. You may return the goods to us to receive a refund, 
                    excluding the postage costs of returning them back to us.</p>
            </div>
            <h2>Payments</h2>
            <div className="info-text">
                <p>WWe accept payments on our online store by credit card only. Payments are handled by a third party provider 
                    (see privacy policy).</p>
            </div>
            <h2>Data and Privacy Policy</h2>
            <div className="info-text">
                <p>Credit card payments are handled by a third party. We do not store, have access to or process any of your credit card information. 
                    This is purely handled securely by a third party provider.</p>
                <p>We collect and store personal data, purely for the purpose of fulfilling orders and having contact information should we need to notify you on the status of your order. 
                    None of the information we collect is shared with third-parties. We do not use data for tracking purposes, promotions or sales.</p>
                <p>The following is collected and stored securely in our database when you make a purchase:</p>
                <p>We will inform you of a data breach, should one occur.</p>
                <ul>
                    <li>Name</li>
                    <li>Address</li>
                    <li>Contact Phone Number</li>
                    <li>Email address</li>
                </ul>
                <p>You have the right for any personal data to be removed from our systems at any time. This can be requested by contacting us directly.</p>
            </div>
        </>
    );
} 

export default TermsText;