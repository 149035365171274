import React from 'react';
import './size-button.styles.scss';


const SizeButton = ({stockLevel, setSize}) => {

    const selectSize = (id, stockLevel) => {
        if(stockLevel > 0) {
            document.querySelectorAll('.size-row-selected').forEach(e => e.classList.remove('size-row-selected'));
            document.querySelector(`#id-size-${id}`).classList.add('size-row-selected');
            setSize(id);
        }
    } 

    return (
        <div>
            {/* <p>{stockLevel.size}</p> */}
            <div id={`id-size-${stockLevel.id}`} className="size-row" onClick={ () => selectSize(stockLevel.id, parseInt(stockLevel.stock_level))}>
                <div className="column">
                    {stockLevel.size}
                </div>
                <div className="column">
                    { parseInt(stockLevel.stock_level) > 0 ? stockLevel.stock_level + ' in stock' : 'Out of stock' }
                </div>
            </div>
        </div>
    );
}

export default SizeButton;